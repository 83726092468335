* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color:#dfecf5;
}

.App{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

